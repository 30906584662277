@import url("../../css/index.css");

.footer {
  background: var(--color-fastuosa-dark) 0% 0% no-repeat padding-box;
  border-top: var(--color-fastuosa-light-orange) solid 2px;
  padding: var(--space-3) 0px;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer_body {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer_body_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-2);
}

.footer_body_titles {
  color: var(--color-fastuosa-light-orange);
  font-size: var(--font-size-h5);
  max-width: var(--space-7) * 3;
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: var(--space-2);
  text-decoration: none;
}

.footer_body_text {
  color: var(--color-fastuosa-light);
  font-size: var(--font-size-body16);
  max-width: var(--space-7) * 3;
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: var(--space-1);
  text-decoration: none;
}

.footer_body_tex_phone {
  text-decoration: none;
  color: var(--color-fastuosa-orange);
}

.footer_body_fbIcon {
  width: 50px;
  margin: var(--space-2) 0px;
}

.footer_bottom {
  display: block;
  color: var(--color-fastuosa-light);
  font-size: var(--font-size-body12);
  padding: 1%;
}

.footer__icon {
  margin-right: var(--space-1);
}
