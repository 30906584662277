.section_sucursales{
    display: flex;
    align-content: space-between;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin: var(--space-7) 0;
}

.container_sucursales_titile{
    text-align: center;
    font-size: var(--font-size-h2);
    font-family: 'Allura';
    color: var(--color-fastuosa-orange);
    margin: 0;
}

.container_sucursales{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.container_sucursales_map{
    width: 100%;
    height: 400px;
}

.container_sucursales_label{
    font-size: var(--font-size-h5);
}

.container_sucursales_text{
    text-align: center;
    font-size: var(--font-size-body16);
}

.container_sucursales_text_phone{
    text-decoration: none;
    font-size: var(--font-size-body18);
    color: var(--color-fastuosa-orange);
    display: block;
}