:root {
  /* Colors */
  --color-fastuosa-orange: #f34e0b;
  --color-fastuosa-light-orange: #ff6920;
  --color-fastuosa-light: #f1ece5;
  --color-fastuosa-dark: #2b2b2b;

  /* Spaces */
  --space-1: 8px;
  --space-2: 16px;
  --space-3: 24px;
  --space-4: 32px;
  --space-5: 64px;
  --space-6: 96px;
  --space-7: 112px;
  --space-8: 124px;
  --space-9: 132px;

  /* typography */
  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;
  
  --font-size-h1: 68px;
  --font-size-h2: 42px;
  --font-size-h3: 32px;
  --font-size-h4: 24px;
  --font-size-h5: 20px;
  --font-size-body28: 28px;
  --font-size-body22: 22px;
  --font-size-body18: 18px;
  --font-size-body16: 16px;
  --font-size-body14: 14px;
  --font-size-body12: 12px;
  --font-size-body10: 10px;
  --font-size-body8: 8px;
  --font-size-body6: 6px;
}

@font-face {
  font-family: "Allura";
  src: url("./fonts/Allura-Regular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Corinthia";
  src: url("./fonts/Corinthia-Bold.ttf") format("truetype");
  font-weight: 100;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Corinthia";
  src: url("./fonts/Corinthia-Regular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PTSans";
  src: url("./fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PTSans";
  src: url("./fonts/PTSans-Bold.ttf") format("truetype");
  font-weight: 100;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "PTSans";
  src: url("./fonts/PTSans-BoldItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: bold italic;
  font-display: swap;
}

@font-face {
  font-family: "PTSans";
  src: url("./fonts/PTSans-Italic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
