.section_menu_pizzas {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
}

.pizzasTable {
  padding: var(--space-3);
}

.pizzasSize {
  color: var(--color-fastuosa-orange);
  padding: calc(var(--space-1) - 3px);
}

.pizzaMenuPrecio {
  text-align: center;
}

.pizzaMenu_Titulo {
  color: var(--color-fastuosa-light-orange);
  margin-bottom: 0px;
}

.pizzaMenu_Descripcion {
  margin-top: 0px;
}

@media (max-width: 750px) and (min-width: 500px) {
  .pizzasTable {
    font-size: var(--font-size-body12);
    width: 80%;
    padding: 0;
  }
}

@media (max-width: 500px) and (min-width: 200px) {
  .pizzasTable {
    font-size: var(--font-size-body8);
    width: 80%;
    padding: 0;
  }
}
