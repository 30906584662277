@import "../../css/index.css";

.bannerHome {
  align-items: center;
  justify-content: center;
}

.each-slide-effect_slide {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 50;
}

.each-slide-effect_slide::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  content: " ";
}

.slide_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 100;
  position: absolute;
}

.slide_container_img {
  width: 25%;
  margin-top: 10%;
  margin-bottom: 0;
}

.slide_container_text {
  text-align: center;
  color: var(--color-fastuosa-orange);
  font-size: calc(var(--font-size-h1) + var(--font-size-h1));
  font-weight: var(--font-weight-medium);
  font-family: Allura;
  margin-bottom: 0;
  margin-top: 0;
}

.slide_container_span {
  text-align: center;
  font-weight: var(--font-weight-regular);
  color: var(--color-fastuosa-light);
  font-size: var(--font-size-h3);
}

@media (max-width: 500px) and (min-width: 300px) {
  .slide_container_img{
    width: 50%;
  }

  .slide_container_text {
    font-size: var(--font-size-h1);
  }

  .slide_container_span {
    font-size: var(--font-size-body18);
  }
}
