.slider_section {
  height: 500px;
  width: 100%;
  position: relative;
  display: flex;
}

.slide-container {
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 50;
}

.slide-container::before {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  content: " ";
}

.slider_section_text {
  width: 100%;
  font-size: calc(var(--font-size-h1) + var(--font-size-h1));
  color: var(--color-fastuosa-light);
  font-family: Allura;
  text-align: center;
  position: absolute;
  z-index: 9;
}

@media (max-width: 700px) and (min-width: 300px) {
  .slider_section_text {
    font-size: var(--font-size-h1);
  }
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider_widthfull {
  width: 100%;
}
