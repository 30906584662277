
.galery{
  width: 100%;
}

.galerySlider{
  width: 90% !important;
  margin-top: 5%;
  margin-bottom: 5%;
}

.galery_title{
  text-align: center;
  color: var(--color-fastuosa-orange);
  font-size: calc(var(--font-size-h1) + var(--font-size-h3));
  font-weight: var(--font-weight-medium);
  font-family: Allura;
  margin-bottom: 0;
  margin-top: 5%;
}

@media (max-width: 912px) and (min-width: 712px) {
  .galery_title{
    font-size: var(--font-size-h1);
  }
}

@media (max-width: 700px) and (min-width: 200px) {
  .galery_title{
    font-size: var(--font-size-h2);
  }
}