.container_History {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.container_History_title_text {
  color: var(--color-fastuosa-orange);
  font-size: var(--font-size-h1);
  font-family: "Allura";
  text-align: center;
}

.container_History_text {
  line-height: 1.47059;
  letter-spacing: -0.022em;
  color: var(--color-fastuosa-dark);
  text-align: justify;
  font-size: var(--font-size-body22);
}

.linkMap{
  text-decoration: none;
  color: var(--color-fastuosa-orange);
}

@media (max-width: 905px) and (min-width: 712px) {
  .container_History {
    max-width: 70%;
  }
  .container_History_title_text {
    font-size: var(--font-size-h2);
  }
}

@media (max-width: 700px) and (min-width: 600px) {
  .container_History {
    max-width: 80%;
  }
  .container_History_title_text {
    font-size: var(--font-size-h2);
  }
}
@media (max-width: 600px) and (min-width: 200px) {
  .container_History {
    max-width: 90%;
  }
  .container_History_title_text {
    font-size: var(--font-size-h3);
  }
  .container_History_text {
    font-size: var(--font-size-body12);
  }
}
