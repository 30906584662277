.container_menuCocina {
  padding: var(--space-4);
}

.menuCategorias {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  margin: var(--space-4) 0;
  padding: 0;
}

.menuCategorias_elements {
  color: var(--color-fastuosa-orange);
  font-family: "Allura";
  font-size: var(--font-size-h3);
  text-decoration: none;
  margin: var(--space-1);
}

.menuCategorias_elements:hover {
  color: var(--color-fastuosa-light-orange);
}
