@import "./css/index.css";

html,
body {
  font-family: PTSans;
  height: 100%;
  margin: 0;
}

body {
  /* background-color: var(--color-fastuosa-dark); */
  background-color: var(--color-fastuosa-light);
  background-image: url(../assets/img/background-menu.png);
}

body::-webkit-scrollbar {
  width: 0;
}

#root {
  position: relative;
  /* height: 2000px; */
}