.article_Menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
  align-items: stretch;
  justify-content: center;
  align-content: center;
  margin-bottom: var(--space-9);
}

@media (max-width: 750px) {
  .article_Menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .container_menu {
    max-width: 250px;
  }
}

.container_image {
  padding: var(--space-4);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}

.container_image_title {
  color: var(--color-fastuosa-orange);
  font-size: calc(var(--font-size-h4) + var(--font-size-h5));
  font-family: "Allura";
  text-align: center;
}

.container_menu {
  max-width: 750px;
  min-width: 450px;
  padding: var(--space-3);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.container_image_img {
  width: 100%;
  filter: drop-shadow(5px 5px 10px #000);
  border: solid 10px var(--color-fastuosa-light);
}

.container_Menu_Platillo {
  color: var(--color-fastuosa-orange);
  text-align: justify;
}
.container_Menu_Platillo_Precio {
  text-align: right;
  float: right;
  margin-left: 1fr;
}
.container_Menu_Descripcion {
  color: var(--color-fastuosa-dark);
  text-align: justify;
}
