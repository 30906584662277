@import url("../../css/index.css");

.navbar {
  align-content: center;
  background-color: var(--color-fastuosa-dark);
  border-bottom: var(--color-fastuosa-light-orange) 2px solid;
  display: flex;
  height: var(--space-9);
  justify-content: center;
  top: 0;
  position: fixed;
  width: 100%;
  opacity: 0.95;
  z-index: 10;
}

.navbar_links {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
}

.navbar_links a {
  display: inline-block;
  margin: 0 var(--space-5);
  font-weight: var(--font-weight-regular);
  color: var(--color-fastuosa-light);
  text-decoration: none;
  font-size: var(--font-size-h4);
  font-family: Allura;
  max-width: var(--space-9);
  min-width: var(--space-8);
  width: var(--space-5);
}

.navbar_logo {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}

.navbar_icon {
  color: var(--color-fastuosa-dark);
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
  line-height: 1;
  min-width: 24px !important;
  min-height: 24px !important;
  max-width: 24px !important;
  max-height: 24px !important;
  margin: var(--space-2);
}

.navbar_icon_index {
  color: var(--color-fastuosa-light);
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
  line-height: 1;
  min-width: 24px !important;
  min-height: 24px !important;
  max-width: 24px !important;
  max-height: 24px !important;
  margin: var(--space-2);
}

.navbar_responsive-menu {
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: var(--color-fastuosa-dark);
}

.navbar_responsive-menu-cross-icon {
  width: 100%;
  text-align: right;
  padding: 15px 35px 0 0;
  color: var(--color-fastuosa-light);
  font-size: var(--font-size-h3);
}

.navbar_responsive-menu-links {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar_responsive-menu-links a {
  display: inline-block;
  margin: var(--space-3) 0;
  font-weight: var(--font-weight-regular);
  color: var(--color-fastuosa-light);
  text-decoration: none;
  font-size: var(--font-size-h3);
  font-family: Allura;
  min-width: var(--space-7);
}

.navbar_responsive-menu-social-media {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--color-fastuosa-light-orange);
  padding: var(--space-4) 0;
  width: 90%;
}

.navbar_responsive-menu-social-media a {
  color: var(--color-fastuosa-light);
  text-decoration: none;
  font-size: var(--font-size-h3);
}

.navbar_responsive-menu-social-media a:hover {
  color: var(--color-fastuosa-light-orange);
}

.navbar_logo-image {
  width: 120px;
}

@media (max-width: 2560px) and (min-width: 1024px) {
  .navbar_links a {
    font-size: var(--font-size-h4);
    min-width: var(--space-8);
  }
  .navbar_icon {
    display: none;
  }
  .navbar_icon_index {
    display: none;
  }
  .navbar_logo-image {
    width: 100px;
  }
}

@media (max-width: 912px) and (min-width: 712px) {
  .navbar_links a {
    margin: 0 calc(var(--space-5) - var(--space-2));
    min-width: var(--space-5);
  }

  .navbar_icon {
    display: none;
  }

  .navbar_icon_index {
    display: none;
  }

  .navbar_logo-image {
    width: 95px;
  }
}

@media (max-width: 700px) {
  .navbar {
    background: none;
    border: none;
    align-items: flex-start;
    justify-content: end;
    padding: 0;
  }

  .navbar_links {
    display: none;
  }

  .navbar_logo-image {
    width: 110px;
  }
}

@media (max-width: 300px) {
  .navbar_responsive-menu-links a {
    color: red;
    font-size: var(--font-size-h3) !important;
  }
}

.nav.default-nav {
  display: none;
}
